<template>
    <div class="has-padding-left-10">
        <img src="../assets/pix.png">
        <div class="column is-12 is-pulled-left">
            <p>
                Página desenvolvida para testes na API de comunicação com o PIX
            </p>
        </div>
        <div class="column is-12 has-margin-top-50 is-pulled-left">
            <p>
                Documentação da API disponível em:
            </p>
            <span class="pix-doc"><a href="https://pix-dev.alterdata.com.br/docs/">https://pix-dev.alterdata.com.br/docs/</a></span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.pix-doc {
    float: left;
    border: 1px solid black;
    border-radius: 5px;
    width: 500px;
    background-color: rgb(46, 66, 131);
    padding: 20px;
    a{
      font-size: 18px;
      color: white;
      font-weight: 500;
    }
}

img {
    width: 180px;
    height: auto;
    padding: 20px 0 0 20px;
};
</style>