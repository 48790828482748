<template>
    <div class="notification-teste column is-12 is-pulled-right is-overlay">
        <article class="message is-pulled-right column is-4" :class="_tipoMensagem">
            <div class="message-header">
                    <p class="is-capitalized">{{ this._mensagemWebService.tipo_mensagem }}</p>
            </div>
            <div class="message-body">
                <div class="column is-12 is-pulled-right">
                    <p> {{ this._mensagemWebService.texto_mensagem }}</p>
                    <p v-if="this._mensagemWebService.resposta_servico === 401"><b>Usuário não autenticado</b></p>
                    <p v-else><b> {{ this._mensagemWebService.resposta_servico}} </b></p>
                </div>
                <input class="has-margin-left-10 has-margin-bottom-10 has-margin-top-10 button is-pulled-right"
                    type="button"
                    :value="`Ok (` + tempoInicial + `) `"
                    :class="_tipoMensagem"
                    @click="fecharMensagem()"/>
                <input v-if="this._mensagemWebService.resposta_completa" class="button is-pulled-right has-margin-10"
                    type="button"
                    value="Detalhar"
                    :class="_tipoMensagem"
                    @click="DetalharCobranca()"/>
            </div>
        </article>
    </div>
</template>

<script>
import store from "@/store";

export default {
    data() {
        return{
        tempoInicial:0
        };
    },
    props: {
            exibirMensagemWebService: Object,
            solicitadoEdicao: Function
    },
    computed: {
        _tipoMensagem() {
            return this.retornarClasseMensagem()
        },
        _mensagemWebService() {
            return store.state.mensagem_web_service
        }
    },
    methods: {
        closeNotification() {
            store.commit('fecharMensagem', false)
        },
        timerCloseNotification() {
            setInterval(() => {
                this.tempoInicial--;
                if(this.tempoInicial === 0){
                return this.closeNotification()
                }
            }, 1000)
        },
        DetalharCobranca() {
            store.commit('fecharMensagem', false)
            this.solicitadoEdicao("ConsultarInvalidar", this._mensagemWebService)
        },
        retornarClasseMensagem() {
            let classeAplicar = ''
            switch (this._mensagemWebService.tipo_mensagem) {
                case 'erro':
                    classeAplicar = 'is-danger'
                    break;
                case 'alerta':
                    classeAplicar = 'is-warning'
                    break;
                case 'sucesso':
                    classeAplicar = 'is-success'
                    break;
                default:
                    classeAplicar = 'is-primary'
                    break;
            }
            return classeAplicar
        },
        fecharMensagem() {
            store.commit('fecharMensagem', false)
        }

    },
    created() {
        this.tempoInicial = 100 ;
        this.timerCloseNotification();
    }
};
</script>

<style scoped>
.notification-header {
  padding: 8px 15px;
  /* height: 100px; */
  /* overflow: hidden; */
  /* width: 100%; */
  transition: all 0.2s;
}

.notification-background {
  padding: 20px;
}

.notification-teste {
  z-index: 999;
}

</style>