<template>
  <div>
    <div class="column box is-12 is-pulled-left"
      id="response" >
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <button @click="limparLog" class="button is-pulled-right column is-3 is-paddingless limparLog">Limpar Log</button>
    </div>
  </div>

</template>

<script>
import eventBus from '@/eventBus'

export default {
  data(){
    return {
      dataAtual: ''
    }
  },
  methods: {
    limparLog() {
      document.getElementById('response').innerHTML  = ""
    },
    horaAtual() {
        this.dataAtual = new Date();
        const localdate = (this.dataAtual.getDate()<10?'0':'') + this.dataAtual.getDate() + '/' + 
                          (this.dataAtual.getMonth()+1) + '/' + 
                          this.dataAtual.getFullYear() + ' ' + 
                          this.dataAtual.getHours() + ':' + 
                          (this.dataAtual.getMinutes()<10?'0':'') + this.dataAtual.getMinutes() + ':' + 
                          this.dataAtual.getSeconds();
      return localdate;
    },
    imprimirJson(json) {
    return "<pre>" + JSON.stringify(json, null, 4).replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</pre>";
    },
    appendMessage(text) {
      document.getElementById('response').innerHTML += "<p>" + text  + "</p>";
      document.querySelector("#response").scrollTop = document.querySelector("#response").scrollHeight

    }
  },
  created() {
    eventBus.$on('envioApi', retorno => {
      this.appendMessage("Envio de solicitação: " + this.horaAtual() + this.imprimirJson(retorno))
    })
    eventBus.$on('retornoApi', retorno => {
      this.appendMessage("retorno recebido: " + this.horaAtual() + this.imprimirJson(retorno))
    })
  },
}
</script>

<style lang="scss" scoped>
 #response {
   overflow: auto;
   height: calc(100vh - 300px);
 }

 .limparLog {
   margin-right: 0;
 }

  input {
    font-size: 0.8rem !important;
    text-align: center !important;
  }
</style>