import axios from 'axios'
import config from 'config'
import store from '@/store'

function listarCobrancas(payload) {
    const requestOptions = {
        params: {
            ...payload
        },
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.apiUrl}/${payload.provedor}`,requestOptions)
    .then(function(response) {
        return response
    }).catch(function (error) {
        gerarMensagem("erro", "Ocorreu um erro: ", error)
        return error
    })
}

function gerarMensagem (tipo, texto, resposta) {
    let payload = {
        exibir_mensagem: true,
        tipo_mensagem:tipo,
        texto_mensagem: texto,
        resposta_servico: resposta
    }
    store.commit('preencherMensagemWebService', payload)
}

export const listarCobrancasService = {
    listarCobrancas
}