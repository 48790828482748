import axios from 'axios'
import config from 'config'
import store from '@/store'

function listarMovimento(payload) {
    const requestOptions = {
        params: {
            dataInicio: payload.dataInicio,
            dataFim: payload.dataFim,
            numeroPagina: payload.numeroPagina,
            itensPorPagina: payload.itensPorPagina,
            inscricaoFederal: payload.inscricao_federal
        },
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }

    return axios(`${config.apiUrl}/lancamentos/movimentos/${payload.provedor}`,requestOptions)
    .then(function(response) {
        return response
    })
    .catch(function (error) {
        gerarMensagem("erro", "Ocorreu um erro: ", error.response ? error.response.data : error)
        return error
    })
}

function gerarMensagem (tipo, texto, resposta) {
    let payload = {
        exibir_mensagem: true,
        tipo_mensagem:tipo,
        texto_mensagem: texto,
        resposta_servico: resposta
    }
    store.commit('preencherMensagemWebService', payload)
}

export const listarMovimentosService = {
    listarMovimento
}