import axios from 'axios'
import config from 'config'

function consultaTransacao(payload) {
    const requestOptions = {
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.apiUrl}/lancamentos/${payload.provedor}/${payload.id_transacao}`,requestOptions)
    .then(function(response) {
        return response.data
    })
}

function invalidarTransacao(payload) {
    const requestOptions = {
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios.delete(`${config.apiUrl}/lancamentos/${payload.provedor}/${payload.id_transacao}`,requestOptions)
    .then(function(response) {
        return response
    })
}

export const consultaInvalidarService = {
    consultaTransacao,
    invalidarTransacao
}