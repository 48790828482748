<template>
  <div>
    <div v-if="zoom_qr_code" class="column is-12 is-pulled-left zoom_qr_code"
      @click="qrCodeZoom()">
      <img
        class="img_qr_code"
        :class="{'zoom_qr_code': zoom_qr_code}"
        :src="`data:image/png;base64,${qrcode_pix}`">
    </div>
    <div class="column is-12 is-pulled-left is-paddingless">
      <div class="column is-9 is-pulled-left is-paddingless has-padding-left-10">
        <div class="column is-3 is-pulled-left is-paddingless has-padding-right-10">
          <span>Provedor</span>
          <div class="select is-fullwidth is-small">
            <select class="input is-small" v-model="post_params.provedor">
              <option v-for="(tipo, i) in _lista_provedores" :key="i"
                :value="tipo.codigo">
                {{ tipo.descricao }}
              </option>
            </select>
          </div>
        </div>
        <div class="column is-6 is-pulled-left is-paddingless has-padding-left-10 has-padding-right-10">
        <span>Informe o ID da transação autorizada</span>
          <input
            class="input is-small"
            v-model="post_params.id_transacao"
            type="text">
        </div>
        <div class="column is-3 is-pulled-left has-padding-top-25 has-padding-bottom-5">
          <button class="button is-small is-fullwidth" @click="consultaTransacao()">Obter dados</button>
        </div>
        <div class="column is-12 is-pulled-left is-paddingless">
          <span>Conteúdo do QrCode</span>
          <qrcode-drop-zone @detect="onDetect" @dragover="onDragOver" @init="logErrors">
            <textarea class='textarea has-fixed-size has-padding-top-5 has-padding-bottom-5 is-size-7'
              readonly
              :class="{ 'dragover': dragover }"
              placeholder="Arraste o QRCode para esta área e veja o conteúdo."
              rows="2"
              v-model="result">
            </textarea>
          </qrcode-drop-zone>
        </div>
      </div>
      <div class="column is-3 is-pulled-left is-paddingless has-padding-top-20">
        <div v-if="qrcode_pix"
          class="is-flex is-flex-direction-row is-justify-content-space-around">
            <img v-if="detalhesConsultaParse === ''"
              class="img_qr_code"
              src="../assets/no-image.png">
            <img v-else
              title="Clique na imagem para aplicar zoom."
              class="img_qr_code"
              :src="`data:image/png;base64,${qrcode_pix}`"
              @click="qrCodeZoom()">
        </div>
      </div>
      <div class="column is-12 is-pulled-left has-padding-left-10 has-padding-top-5">
        <span>Dados do retorno da consulta</span>
        <textarea class='textarea has-fixed-size retorno-consulta-textarea' readonly v-model="detalhesConsultaParse"></textarea>
      </div>
      <div class="column is-12 is-pulled-left is-paddingless">
        <div class="column is-2 is-pulled-right">
          <button class="button is-small is-fullwidth" @click="confirmarInutizacao">Invalidar</button>
        </div>
      </div>
    </div>
    <div class="column is-12 is-pulled-right is-overlay"
      v-if="mensagem_confirmacao">
      <div class="is-flex is-flex-direction-row is-justify-content-space-around has-margin-top-100">
        <article class="message column is-4 is-danger">
          <div class="message-header">
            <p class="is-capitalized">Invalidar transação</p>
          </div>
          <div class="message-body">
            <div class="column is-12 is-pulled-right">
              <p>A transação {{post_params.id_transacao}} será invalidada, continuar?</p>
            </div>
          </div>
          <input class="button is-pulled-right has-margin-top-10 has-margin-left-10 is-danger"
            type="button"
            value="Não"
            @click="invalidarTransacao('nao')"/>
          <input class="button is-pulled-right has-margin-top-10 has-margin-right-10 is-danger"
            type="button"
            value="Sim"
            @click="invalidarTransacao('sim')"/>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { QrcodeDropZone } from "vue-qrcode-reader";
export default {
  components: {
    QrcodeDropZone
  },
  props: {
    payload_consulta: [Object, String]
  },
  computed: {
    _lista_provedores() {
      return store.state.lista_provedores
    }
  },
  data() {
    return {
      post_params: {
        provedor: '',
        id_transacao: ''
      },
      result: null,
      error: null,
      dragover: false,
      qrcode_pix: '',
      detalhesConsultaParse: '',
      zoom_qr_code: false,
      mensagem_confirmacao: false
    }
  },
  methods: {
    async onDetect (promise) {
      try {
        const {
          content      // decoded String or null
        } = await promise

        if (content === null) {
          // decoded nothing
        } else {
          this.onDecode(content)
        }
      } catch (error) {
        if (error.name === 'DropImageFetchError') {
          // drag-and-dropped URL (probably just an <img> element) from different
          // domain without CORS header caused same-origin-policy violation
        } else if (error.name === 'DropImageDecodeError') {
          // drag-and-dropped file is not of type image and can't be decoded
        } else {
          // idk, open an issue ¯\_(ツ)_/¯
        }
      }
    },
    onDecode (result) {
      this.result = result
    },
    logErrors (promise) {
      promise.catch(console.error)
    },
    qrCodeZoom () {
      this.zoom_qr_code = this.zoom_qr_code ? false : true
    },
    onDragOver (is_dragging_over) {
      this.dragover = is_dragging_over
    },
    confirmarInutizacao(){
      this.mensagem_confirmacao = true
    },
    invalidarTransacao(botao_precionado){
      this.mensagem_confirmacao = false
      if(botao_precionado === 'sim') {
        store.commit('exibirModalLoading', true)
        store.dispatch('InutilizaTransacao' , this.post_params).then(response => {
          let mensagem = {
             exibir_mensagem: true,
             tipo_mensagem: "sucesso",
             texto_mensagem: response.status === 202 ? "Transação aceita": "Status: " + response.status
          }
          store.commit('preencherMensagemWebService', mensagem)
        },
          error => {
            let payload = {
              exibir_mensagem: true,
              tipo_mensagem:"erro",
              texto_mensagem: "ocorreu um problema.",
              resposta_servico: error.response ? error.response.data : error
            }
          store.commit('preencherMensagemWebService', payload)
        })
        .finally(() => {
          store.commit('exibirModalLoading', false)
        })
      }
    },
    consultaTransacao() {
      store.commit('exibirModalLoading', true)
      store.dispatch('consultaTransacao' , this.post_params).then(response  => {
        this.detalhesConsultaParse = JSON.stringify(response, null, 2);
        this.qrcode_pix = response.imagem;
      }, error => {
        let payload = {
          exibir_mensagem: true,
          tipo_mensagem:"erro",
          texto_mensagem: "ocorreu um problema.",
          resposta_servico: error
        }
        store.commit('preencherMensagemWebService', payload)
      }).finally(() => {
        store.commit('exibirModalLoading', false)
      })
    }
  },
  created() {
    if(this.payload_consulta) {
      this.post_params.provedor = this.payload_consulta.provedor,
      this.post_params.id_transacao = this.payload_consulta.resposta_servico
      this.qrcode_pix = this.payload_consulta.resposta_completa.imagem
      this.detalhesConsultaParse = JSON.stringify(this.payload_consulta.resposta_completa, null, 2)
    }
    let ultimaAutorizacao = JSON.parse(localStorage.getItem("ms-pix-cobranca-autorizada"))
    if(ultimaAutorizacao) {
      this.post_params.id_transacao = ultimaAutorizacao.id
      this.post_params.provedor = ultimaAutorizacao.provedor
    }
  }
};
</script>

<style lang="scss" scoped>

.zoom_qr_code {
  float: right;
  position: absolute;
  transform: scale(1.5);
  z-index: 2;
  margin: 40px 0 0 260px !important;
  .img_qr_code {
    border: gray 1px solid;
    cursor: zoom-out;
  }

}

.retorno-consulta-textarea {
  height: calc(100vh - 400px);
}

.drop-area {
  padding: 10px;
}

.dragover {
  background-color: rgba(0,0,0,.5);
}
.dragover::placeholder {
  color: honeydew;
}

.drop-error {
  color: red;
  font-weight: bold;
}

.img_qr_code {
  width: 140px;
  height: 140px !important;
  margin: -14px;
  cursor: zoom-in;
}

</style>