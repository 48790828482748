<template>
    <div id="app-mf-atena-pix">
        <script type="module" src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js" />
        <transition name="slide">
          <MensagensRetorno v-if="_exibirMensagem"
            :solicitadoEdicao="detalharTransacao"/>
        </transition>
        <atena-utils-modal-loading v-if="_modalLoading"/>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless has-text-centered">
          <img src="./assets/pix.png" @click="componenteSelecionado('Apresentacao')" class="titulo-img is-pulled-left has-margin-left-15 is-clickable">
          <span class="is-pulled-left has-margin-left-30 is-size-4">
            Integrador para o projeto MS-Pix
          </span>
        </div>
        <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10"/>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
          <section>
            <aside class="menu column is-2 is-pulled-left is-marginless is-paddingless">
              <ul class="menu-list has-padding-left-15">
                <li>
                  <p class="menu-label">Cobranças</p>
                  <ul class="menu-itens">
                    <li :style="this.componente === 'EnviarCobranca' && this.template === 'dinamico' ? funcionalidadeSelecionada : ''"
                        id="menu-btn-cobranca-dinamica"
                        @click="componenteSelecionado('EnviarCobranca','dinamico')">Dinâmica
                    </li>
                    <li :style="this.componente === 'EnviarCobranca' && this.template === 'estatico' ? funcionalidadeSelecionada : ''"
                        id="menu-btn-cobranca-estatica" 
                        @click="componenteSelecionado('EnviarCobranca','estatico')">Estática
                    </li>
                    <li :style="this.componente === 'EditarCobranca' ? funcionalidadeSelecionada : ''"
                        id="menu-btn-editar-cobranca" 
                        @click="componenteSelecionado('EditarCobranca')">Editar
                    </li>
                  </ul>
                  <p class="menu-label">Consultas</p>
                  <ul class="menu-itens">
                    <li :style="this.componente === 'ConsultarInvalidar' ? funcionalidadeSelecionada : ''"
                        id="menu-btn-consultar-invalidar" 
                        @click="componenteSelecionado('ConsultarInvalidar')">Obter / Invalidar
                    </li>
                    <!--<li :style="this.componente === 'ListarCobrancas' ? funcionalidadeSelecionada : ''">
                      <a @click="componenteSelecionado('ListarCobrancas')">
                      <i  class="fas fa-list fa-sm has-padding-right-5" aria-hidden="true"></i>Listar Cobranças</a>
                    </li>-->
                  </ul>
                  <p class="menu-label">Listar</p>
                  <ul class="menu-itens">
                     <li :style="this.componente === 'ListarMovimentos' ? funcionalidadeSelecionada : ''"
                        id="menu-btn-listar-movimentos" 
                        @click="componenteSelecionado('ListarMovimentos')">Movimentos
                    </li>
                  </ul>
                </li>
              </ul>
            </aside>
            <div class="column is-10 is-pulled-left is-paddingless has-padding-right-10 cardPersonalizado">
              <div>
                <component
                  v-bind:is="componente"
                  :payload_consulta="payload_consulta"
                  :template="template">
                </component>
              </div>
            </div>
          </section>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import store from './store'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, required_if } from 'vee-validate/dist/rules';
import Apresentacao from '@/components/apresentacao'
import LogRetornoEnvio from '@/components/LogRetornoEnvio.vue'
import ConsultarInvalidar from '@/views/consultarInvalidar.vue'
import MensagensRetorno from '@/utils/mensagensRetorno'

const EnviarCobranca = () => import(/* webpackChunkName: "EnviarCobranca", webpackPrefetch: true */ '@/views/enviarCobranca.vue')
const EditarCobranca = () => import(/* webpackChunkName: "EditarCobranca", webpackPrefetch: true */ '@/views/editarCobranca.vue')
const ListarCobrancas = () => import(/* webpackChunkName: "ListarCobrancas", webpackPrefetch: true */ '@/views/listarCobrancas.vue')
const ListarMovimentos = () => import(/* webpackChunkName: "ListarMovimentos", webpackPrefetch: true */ '@/views/listarMovimentos.vue')

extend("required", {
  ...required,
  message: "{_field_} é obrigatório."
});

extend("required_if", {
  ...required_if,
  message: "{_field_} é obrigatório."
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

export default {
  components: {
    store,
    Apresentacao,
    EnviarCobranca,
    EditarCobranca,
    ConsultarInvalidar,
    ListarMovimentos,
    ListarCobrancas,
    LogRetornoEnvio,
    MensagensRetorno
  },
  props: {
    teste: String, 
    frase: String
  },
  data() {
    return {
      funcionalidadeSelecionada: {
        'background-color': 'rgb(150 160 193 / 58%)',
        'border-radius': '3px'
      },
      componente: 'Apresentacao',
      payload_consulta: '',
      template: ''
    }
  },
  computed: {
    _modalLoading() {
      return store.state.modal_loading
    },
    _exibirMensagem() {
      return store.state.mensagem_web_service.exibir_mensagem
    }
  },
  methods: {
    componenteSelecionado(componente_selecionado, template) {
      this.componente = componente_selecionado
      componente_selecionado === 'EditarCobranca' || componente_selecionado === 'EnviarCobranca' ? template ? this.template = template : '' : ''
    },
    detalharTransacao(componenteDestino,payload){
      this.componente = componenteDestino
      this.payload_consulta = payload
      componenteDestino === 'EditarCobranca' || componenteDestino === 'EnviarCobranca' ? this.template = payload.tipo_qrcode : ''
    }
  }
}
</script>

<style lang='scss'>
  @import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css");
  @import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
  @import "./comum/styles/main.scss";
  @import '~@fortawesome/fontawesome-free/css/all.css';
  .cardPersonalizado {
    background-color: snow !important;
    border-radius: 5px;
  }

  .menu-itens {
    li{
      padding: 5px 10px;
    }
    li:hover{
      background-color: #f5f5f5;
      cursor: pointer;
      display: block !important;
    }

  }

</style>