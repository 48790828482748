import Vue from 'vue'
import Vuex from 'vuex'
import { enviarCobrancaDinamicaService } from '../services/cobrancaDinamica.service'
import { enviarCobrancaEstaticaService } from '../services/cobrancaEstatica.service'
import { consultaInvalidarService } from '../services/consultaInvalidar.service'
import { listarCobrancasService } from '../services/listarCobrancas.service'
import { listarMovimentosService } from '../services/listarMovimentos.service'

Vue.use(Vuex)

/*var teste = {
  provedor: "ITAU",
  inscricao_federal: "03855629773",
  valor: "10000.02",
  tipo_chave: "CPF",
  chave_enderecamento: "03855629773"
}*/

/*var teste = {
  valor: "99.00",
  calendario: {
    criacao: "2021-01-21T17:44:09",
    data_vencimento: "2021-01-23",
    data_multa: "2021-01-23",
    data_desconto: "2021-01-22",
    data_juros: "2021-01-23"
  },
  emv: "00020101021126770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v1/e187970d-cf61-4602-b1bf-f89a56024f5f5204000053039865802BR5918HENRIQUE L REBELLO6009SAO PAULO62070503***6304EB26",
  imagem: "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACt0lEQVR4Xu2XQW4EIQwE4SP4/7/IU8xHmHQ1iZbsIcoh49Og3SwDFcmyuw3Trt/HR3tfeRsPsMcD7PEAe/wNyNb6vKZm0/MVemJSCGhr5uiXNlK72fTpLFYC2WcG8bWebUSOWJ3FYmASV8YaztMaxFsNXEu1ScXYRlOiYi8WAjBjtJhTSlG1gl+vlwEW7fs4RVsAMBaC6RdBKtyebDKqgAw9o5hUobr0yhzpFALSxrX05LW5+i6WqlYIrNG1bLfQxQK5oqBKgLTIMDE1JdYW+uKfQkCpYW2bl2JtRKEWArGFikKUpEk3iR/Fuh9QUMrUUHXUyvsu1Y60DlCKtk0EZpCumJwulYAVo+NkG1csHs6jWCUAHlnWjEEbKF7FKgAokYrVrZRG4QLzvhJVAQQxoRJ9+aVQ63T3/QCM+oebmIIL/ijSo1j3A1LpcKWEjS1Wd9IoBJSmxjqXjEYrk1TSHqoDJFQqZeNwuAseRFwJaHvrtXuS9NJJH6sFvvwLkoQo7mzmBUA6Pi47DakqXW/VvB+4sI3N4kbCVOY5DpQCYPqWg1wtmUQuAo5iFQDS5+QlUAmyUFbw86NYtwMOST3sYrkB8FAKkCNefWSd0ahYotrT3fcDScdAI/jHxdJ50o9iVQDBTtuv44Y7GnolqgBAIOqjSg5runwqWVz8KgH0qSwRZ8M6+Hie5i0AJnE6MuRivbCCj8qAZZNk90eBduq3jmKVAHr6Spa1Sq3WcdmrAJjKvU6VNEuCOFwqAfIz2CNPJEr/kTwVAgwMvA93pQzNYqdCgIAQrTuHbzu0Eu7gdYDCw7oSiYPU1WK4n5YC6l8SiDaJl/vFLtYsBqzSZrlMjjcrtxhQhUYoxvFt433nqwMoFqEpVdPL3IFt6TLAhVHT6s6QhLv8aPdWAb+NB9jjAfZ4gD3+AfgEOsHFnk/0gn8AAAAASUVORK5CYII=",
  pago: false,
  status: "ATIVO",
  tipo_chave: "CPF",
  chave_enderecamento: "03855629773",
  valores_adicionais: {
    juros: "1.00",
    multa: "1.00",
    desconto: "1.00",
    permite_alteracao: "true"
  },
  recebivel_apos_vencimento: true,
  tipo_qrcode: "dinamico"
}*/

export default new Vuex.Store({
  state: {
    parametros_edicao: {},
    modal_loading: false,
    chaveSeguranca: '',
    mensagem_web_service: {
      exibir_mensagem: false,
      tipo_mensagem: "",
      texto_mensagem: "",
      resposta_servico: ""
    },
    dados_provedor: "",
    cob_estatica_params: {},
    cob_dinamica_params: {},
    lista_provedores: [
      {descricao: "Itaú" ,codigo: "ITAU"},
      {descricao: "Banco do Brasil" ,codigo: "BB"}
    ]
  },
  mutations: {
    preencherCobEstaticaParams(state,payload){
      state.cob_estatica_params = payload;
    },
    preencherCobDinamicaParams(state,payload){
      state.cob_dinamica_params = payload;
    },
    armazenarToken(state,payload) {
      state.chaveSeguranca = payload;
    },
    preencherMensagemWebService(state, payload) {
      state.mensagem_web_service = payload
    },
    fecharMensagem(state, payload) {
      state.mensagem_web_service.exibir_mensagem = payload
    },
    exibirModalLoading(state, payload) {
      state.modal_loading = payload
    }
  },
  actions: {
    guardarDadosDinamica(commit, payload) {
      if(payload.provedor){
        let itemKey = ("ms_pix_" + payload.provedor + "_dinamica").toUpperCase()
        localStorage.setItem(itemKey, JSON.stringify(payload));
      }
    },
    guardarDadosEstatica(commit, payload) {
      if(payload.provedor){
        let itemKey = ("ms_pix_" + payload.provedor + "_estatica").toUpperCase()
        localStorage.setItem(itemKey, JSON.stringify(payload));
      }
    },
    enviarCobrancaDinamica(commit, payload) {
      return new Promise((resolve, reject) => {
        enviarCobrancaDinamicaService.enviar(payload).then(response => {
          return resolve(response)
        }, error => {
          return reject(error)
        })
      })
    },
    enviarCobrancaDinamicaEdicao(commit, payload) {
      return new Promise((resolve, reject) => {
      enviarCobrancaDinamicaService.enviarEdicao(commit, payload).then(response => {
          return resolve(response)
        }, error => {
          return reject(error)
        })
      })
    },
    enviarCobrancaEstatica(commit, payload) {
      return new Promise((resolve, reject) => {
        enviarCobrancaEstaticaService.enviar(commit, payload).then(response => {
          return resolve(response)
        }, error => {
          return reject(error)
        })
      })
    },
    listarMovimentos(commit, payload) {
      return new Promise((resolve, reject) => {
        listarMovimentosService.listarMovimento(payload).then(response => {
          return resolve(response)
        }, error => {
          return reject(error)
        })
      })
    },
    listarCobrancas(commit, payload) {
      return new Promise((resolve, reject) => {
        listarCobrancasService.listarCobrancas(payload).then(response => {
          return resolve(response)
        }, error => {
          return reject(error)
        })
      })
    },
    consultaTransacao(commit, payload) {
      return new Promise((resolve, reject) => {
        consultaInvalidarService.consultaTransacao(payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    InutilizaTransacao(commit, payload) {
      return new Promise((resolve, reject) => {
        consultaInvalidarService.invalidarTransacao(payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    }
  },
  modules: {
  }
})
