import axios from 'axios'
import config from 'config'

function enviar(commit, payload) {

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload),
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.apiUrl}/lancamentos/estatico/criar`,requestOptions)
    .then(function(response) {
        return response
    })
}

export const enviarCobrancaEstaticaService = {
    enviar
}