import axios from 'axios'
import config from 'config'

function enviar(payload) {
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload),
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }

    return axios(`${config.apiUrl}/lancamentos/dinamico/criar`,requestOptions)
    .then(function(response) {
        return response
    })
}

function enviarEdicao(commit, payload) {

    const requestOptions = {
        method: 'put',
        data: JSON.stringify(payload.post_params),
        headers: {
            // 'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.apiUrl}/lancamentos/dinamico/${payload.id_transacao}`,requestOptions)
    .then(function(response) {
        return response
    })
}


export const enviarCobrancaDinamicaService = {
    enviar,
    enviarEdicao
}